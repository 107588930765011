import React from 'react'
import { Link } from 'gatsby'
import Image from '../image/bg-image'
import './talent-block.sass'

const TalentBlock = ({ data }) => (
  <article className="TalentBlock">
    <Link
      className="TalentBlock__link"
      to={data.url}>
      {data.thumb && data.thumb.url &&
        <Image src={data.thumb.url} small={true} />
      }
      <h2>
        {data.name_eng}
        <br />
        <span>{data.name_jap}</span>
      </h2>
      <p className="TalentBlock__job">
        {data.job_title}
      </p>
    </Link>
  </article>
)

export default TalentBlock
