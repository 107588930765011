import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/seo/seo'
import Layout from '../components/layouts/layout'
import TalentList from '../components/talent/talent-list'
import './talent.sass'

const Page = ({ data }) => (
  <Layout className="Talent">
    <Seo
      title="Talent - Maison Hanko"
      description="A pop-up roster of directorial and illustrative talent, Maison Hanko will spotlight the unique creativity of Japanese talent throughout the Tokyo 2020 Cultural Olympiad." />
    <div className="Talent__hero">
      <div className="u-wrap">
        <h1 className="Talent__hero__title">
          <span>A diverse selection of</span> <span>
          outstanding creative talent</span> <span>
          curated by Nexus Studios.</span>
        </h1>
      </div>
    </div>
    <TalentList data={data.allMarkdownRemark.edges} />
  </Layout>
)

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex : "/content/artists/" },
      },
      sort: {
        fields: [frontmatter___order],
        order: ASC
      }
    ) {
      edges {
        node {
          frontmatter {
            name_eng
            name_jap
            thumb {
              url
            }
            job_title
            url
          }
        }
      }
    }
  }
`

export default Page
