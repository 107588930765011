import React from 'react'
import Masonry from 'react-masonry-css'
import TalentBlock from './talent-block'
import './talent-list.sass'

const breakpointColumnsObj = {
  default: 2,
  767: 1,
}

const getTalent = (items) => {
  return items.map((item, i) => {
    return (
      <TalentBlock
        key={i}
        data={item.node.frontmatter} />
    )
  })
}

const WorkList = ({ data }) => (
  <div className="TalentList">
    <div className="TalentList__wrap">
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="TalentList__grid"
        columnClassName="TalentList__grid-column">
        {getTalent(data)}
      </Masonry>
    </div>
  </div>
)

export default WorkList
